<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">

			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<material-card icon="mdi-logout" icon-small title="" color="primary">
					<h3>You have been logged out</h3>
					<v-btn color="primary" class="mt-5" :to="{name:'Login'}">Log in</v-btn>
				</material-card>

			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {get} from "vuex-pathify";

export default {
	name: 'Logout',
	computed: {
		user: get('user/user'),
		token: get('user/token')
	},
	beforeMount() {
		this.logout()
	},
	methods: {
		logout() {
			this.$store.set('user/logout!', null)
		}
	}
}
</script>